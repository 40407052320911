import { ui, select, transform, when, s } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import MegaNav from '../../../OC/meganav';
import { filterEntity, isItemVisible } from '../../../PageBuilder/helpers/content';
import Content from './Content';

const ItemMeta = {
  link_type: ui`Choices`.of({
    text: 'Text Link',
    card: 'Card',
    header: 'Header',
    decal: 'Decal',
    subcolumn: 'Subcolumn',
    'article-preview': 'Article',
  })({
    label: 'Link Type',
    default: 'text',
  }),
  image: ui`Picture`({
    label: 'Image/Icon',
    visible: select`../link_type`.from({text: false, card: true, header: false, decal: true, subcolumn: false, 'article-preview': false}) ,
  }),
  title: ui`Text`({
    label: select`../link_type`.from({ text: 'Title', card: 'Title', header: 'Title', subcolumns: 'Title', 'article-preview': 'Link Title' }) ,
    visible: when`../link_type`.isnt.equal.to('decal'),
  }),
  description: ui`Text`({
    label: 'Description',
    visible: select`../link_type`.from({text: false, card: true, header: false, decal: false, subcolumn: false, 'article-preview': true}),
    multiline: true,
  }),
  url: ui`Text`({
    label: 'URL',
    visible: select`../link_type`.from({text: true, card: true, header: true, decal: false, subcolumn: false, 'article-preview': true}),
  }),
  is_new: ui`Switch`({
    label: '"New Badge"',
    visible: select`../link_type`.from({text: true, card: true, header: false, decal: false, subcolumn: false, 'article-preview': false})
  }),
  right: ui`Number`({
    label: 'Right',
    visible: when`../link_type`.is.equal.to('decal'),
  }),
  bottom: ui`Number`({
    label: 'Bottom',
    visible: when`../link_type`.is.equal.to('decal'),
  }),
  width: ui`Number`({
    label: 'Width',
    visible: when`../link_type`.is.equal.to('decal'),
  }),
  height: ui`Number`({
    label: 'Height',
    visible: when`../link_type`.is.equal.to('decal'),
  }),
};

const ColumnMeta = {
  details: ui`Form`.of({
    title: ui`Text`({ label: 'Column Header' }),
    analytics: ui`Text`({ label: 'Analytics' }),
    is_link: ui`Switch`({ label: 'Column Text Link' }),
    link: ui`Form`.of({
      title: ui`Text`({ label: 'Text Link Title' }),
      url: ui`Text`({ label: 'Text Link URL' }),
    })({
      visible: when`../is_link`.is.equal.to(true)
    }),
    line_rule: ui`Choices`.of({
      on: 'On',
      off: 'Off'
    })({
      label: 'Line Rule',
      default: 'on'
    }),
  }),
  children: ui`List`.of(ItemMeta)({
    label: 'Column Items',
    singular: 'Column Item',
    title: 'title'
  })
};

const NavItemMeta = {
  details: {
    title: ui`Text`({ label: 'Title', default: 'Title' }),
    showTitleOverride: ui`Switch`({ label: 'Customize Title Display Format', default: false }),
    titleOverride: ui`Text/Rich`({
      label: 'Formatted Title',
      default: select`../title`,
      Toolbar: false,
      visible: when`../showTitleOverride`.is.equal.to(true)
    }),
    analytics: ui`Text`({ label: 'Analytics' }),
    cta: ui`Switch`({ label: 'CTA Styling' }),
    url: ui`Text`({ label: 'Link' }),
  }
};

const Meganav = Content(
  'Meganav',
  (UI) => ({
    version: '0.2',
    displayName: 'Meganav',
    usage: 'type',
    pathTip: 'All pages at and below the selected path will use this nav. If you do not see a path you want, please create a page first.',
    read: ({contents, metadata}) => {
      if (!_.isEmpty(contents) && !Array.isArray(contents)) {
        return { contents, metadata };
      }
      return {
        contents: {
          items: (_.isEmpty(contents) ? [] : contents).map(item => {
            const { children, ...rest } = item;
            return {
              ...rest,
              children: (children||[]).map(column => {
                const { children, ...rest } = column;
                return {
                  ...rest,
                  children: (children||[]).map(item => {
                    const { image, ...rest } = item;
                    return {
                      ...rest,
                      image: image ? {
                        source: image.file,
                        alt: image.alt
                      } : undefined
                    };
                  })
                };
              })
            };
          }),
          footer: metadata?.layouts?.Footer?.children
        },
        metadata: _.omit(metadata, 'layouts')
      };
    },
    write: ({contents, metadata}) => {
      return {
        contents: contents.items.map(item => {
          const { children, ...rest } = item;
          return {
            ...rest,
            children: (children||[]).map(column => {
              const { children, ...rest } = column;
              return {
                ...rest,
                children: (children||[]).map(item => {
                  const { image, ...rest } = item;
                  return {
                    ...rest,
                    image: image ? {
                      file: image.source,
                      alt: image.alt
                    } : undefined
                  };
                })
              };
            })
          };
        }),
        metadata: { ...metadata, layouts: { Footer: { children: contents.footer } } }
      };
    },
    variables: {
      focus: { phase: 'list' }
    },
    sidebar: {
      builder: select`~focus/phase`.from({
        list: {
          items: ui`List`.of({
            children: ui`List`({
              title: (item) => item.details?.title,
              singular: 'Column',
              max: 4,
              minimal: true,
              onClick: Meganav.Column,
              onAdd: (add) => add(Board.Evaluate(ColumnMeta))
            })
          })({
            title: 'details/title',
            singular: 'Nav Item',
            onAdd: (add) => add(Board.Evaluate(NavItemMeta)),
            actions: [
              {
                label: 'Settings',
                icon: 'cogs',
                onClick: Meganav.Item
              }
            ],
            startOpened: select`~lastOpenedSection`
          }),
          footer: ui`List/Item`.of(
            ui`List`.of({
              title: ui`Text`({ label: 'Title' }),
              url: ui`Text`({ label: 'URL' }),
            })({
              singular: 'Item',
              title: 'title',
            })
          )({
            title: 'Nav Footer (Mobile Only)'
          }),
        },
        item: {
          heading: ui`Heading`({
            subheading: Meganav.Item.Title,
            heading: 'Nav Item Settings',
            onBack: Meganav.Back
          }),
          'items/~focus:item': ui`Form`.of(NavItemMeta)
        },
        column: {
          heading: ui`Heading`({
            subheading: Meganav.Item.Title,
            heading: Meganav.Column.Title,
            onBack: Meganav.Back
          }),
          'items/~focus:item/children/~focus:column': ui`Form`.of(ColumnMeta)
        }
      }),
    },
    view: Meganav.Renderer(UI),
  })
);

Meganav.Back = () => Board.select({ focus: { phase: 'list' }, lastOpenedSection: Board.focus.section });

Meganav.Item = (item) => Board.select({ focus: { phase: 'item', item } });

Meganav.Item.Title = when`../items/~focus:item/details/title`.is.present
  .then(select`../items/~focus:item/details/title`)
  .otherwise(transform`~focus:item`((index) => `Nav Item ${ parseInt(index) + 1 }`));

Meganav.Column = (column) => Board.select({ focus: { phase: 'column', item: Board.Path.at(-2), column } });

Meganav.Column.Title = when`../items/~focus:item/children/~focus:column/details/title`.is.present
  .then(select`../items/~focus:item/children/~focus:column/details/title`)
  .otherwise(transform`~focus:column`((index) => `Column ${ parseInt(index) + 1 }`));


Meganav.Renderer = (UI, parameters={}) => ({
  contents: ({ value }) => (
    <Content.PreventClicks>
      <div style={ { height: '1000px' } }>
        <MegaNav meganavItems={ value.items } navFooterItems={filterEntity(value.footer, isItemVisible)} />
      </div>
    </Content.PreventClicks>
  )
});

export default Meganav;
